import {motion, AnimatePresence} from 'framer-motion';
import {forwardRef, useImperativeHandle, useState, useRef, useEffect} from 'react';

import PropTypes from 'prop-types';

const Modal = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);
    let modalContentWrapper = useRef(null);

    useImperativeHandle(ref, () => {
        return{
            open: () => setOpen(true),
            close: () => setOpen(false),
        };
    });

    useEffect(() => {
        if(modalContentWrapper.current){
            modalContentWrapper.current.style.maxHeight = `${props.maxHeight ? props.maxHeight : "600px"}`;
            modalContentWrapper.current.style.maxWidth = `${props.maxWidth ? props.maxWidth : "500px"}`;
            modalContentWrapper.current.style.border = `${props.border ? props.border : ""}`;
        }

        // eslint-disable-next-line
    }, [open]);

    return(
        <AnimatePresence>
            {
                open && (
                    <>
                        <motion.div 
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 0.3
                                }
                            }}
                            exit={{
                                opacity: 0,
                                transition: {
                                    delay: 0.3,
                                }
                            }}
                            onClick={() => setOpen(false)}
                            className="fixed h-screen w-screen bg-[rgba(0,0,0,0.6)] z-[10000] left-0 top-0"/>

                        <motion.div 
                            initial={{
                                scale: 0
                            }}
                            animate={{
                                scale: 1,
                                transition: {
                                    duration: 0.3
                                }
                            }}
                            exit={{
                                scale: 0,
                                transition: {
                                    delay: 0.3,
                                }
                            }}
                            className="fixed w-[90vw] h-[90vh] z-[10001] m-auto p-[2vw] rounded-[15px] inset-0" ref={modalContentWrapper}>

                            <motion.div 
                                initial={{
                                    x: 0,
                                    opacity: 0,
                                }}
                                animate={{
                                    x: 0,
                                    opacity: 1,
                                    transition: {
                                        delay: 0.3,
                                        duration: 0.3
                                    },
                                }}
                                exit={{
                                    x: 100,
                                    opacity: 0,
                                    transition: {
                                        duration: 0.3
                                    }
                                }}
                                className="w-full h-full flex flex-col justify-evenly items-center" style={props.scrollableContent ? {overflowY: "scroll"} : {}}>

                                {props.children}

                            </motion.div>

                        </motion.div>
                    </>
                )
            }
        </AnimatePresence>
    );
});

Modal.propTypes = {
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    border: PropTypes.string,
    scrollableContent: PropTypes.bool,
}

Modal.defaultProps = {
    maxHeight: 600,
    maxWidth: 500,
    border: "",
    scrollableContent: false,
}

export default Modal;
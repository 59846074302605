const AnclaSuave = ({ href, className, children }) => {
    const handleClick = (e) => {
      e.preventDefault();
      const targetElement = document.querySelector(href);
  
      if (targetElement) {
        const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        const offset = window.innerHeight / 2; // Desplazamiento para centrar el elemento
        const finalPosition = targetPosition - offset;
  
        window.scrollTo({
          top: finalPosition,
          behavior: 'instant',
        });
      }
    };
  
    return (
      <a className={className} href={href} onClick={handleClick}>
        {children}
      </a>
    );
  };
  
  export default AnclaSuave;
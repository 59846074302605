import { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import Modal from './Modal'
import FormGenerator from '../formGenerator/FormGenerator'
import { contactInputs } from '../header/contactForm/contactInputs'

export default forwardRef(function ContactModal(props, ref) {
  const apiKey = process.env.REACT_APP_BREVO_API_KEY

  const [isMobile, setIsMobile] = useState(false)

  const modal = useRef(null)
  const formRef = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      open: () => {
        if (window.innerWidth < 1280) {
          setIsMobile(true)
        } else {
          setIsMobile(false)
        }

        modal.current.open()
      },
    }
  })

  async function handleFormSubmit({ values }) {
    if (!formRef.current.validate()) {
      alert('Por favor, revisa los campos marcados en rojo.')
      return
    }

    let indice = 0

    const formInformation = [
      values.name,
      values.surname,
      values.email,
      values.phone,
      values.date,
      values.numberOfGuests,
      values.time,
      values.ceremony,
      values.description,
      values.email,
      values.email,
    ]

    let html = await (await fetch('./emailTemplates/eventRequest.html')).text()

    html = html.replace(/\[\]/g, () => formInformation[indice++] || '')

    let sendSmtpEmail = {
      subject: 'Solicitud de evento de ' + values.name + ' ' + values.surname,
      to: [
        {
          email: 'direccion@excelencya.es',
          name: 'María Fernández',
        },
      ],
      sender: {
        name: 'Web Excelencya',
        email: 'web@excelencya.es',
      },
      htmlContent: html,
      params: {
        name: values.name,
        surname: values.surname,
        email: values.email,
        phone: values.phone,
        date: values.date,
        numberOfGuests: values.numberOfGuests,
        time: values.time,
        ceremony: values.ceremony,
        description: values.description,
        reponseEmail: values.email,
      },
      user: {
        name: values.name,
        surname: values.surname,
        email: values.email,
        phone: values.phone,
        date: values.date,
        numberOfGuests: values.numberOfGuests,
        time: values.time,
        ceremony: values.ceremony,
        description: values.description,
        reponseEmail: values.email,
      },
      //templateId: 1,
    }

    fetch('https://api.brevo.com/v3/smtp/email', {
      body: JSON.stringify(sendSmtpEmail),
      headers: {
        Accept: 'application/json',
        'Api-Key': apiKey,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then((data) => {
        modal.current.close();
        alert('¡Gracias por tu mensaje! Nos pondremos en contacto contigo lo antes posible.')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Modal maxHeight="90vh" maxWidth={isMobile ? '90vw' : '60vw'} ref={modal}>
      <div className="w-full h-full bg-primary-light flex flex-col justify-evenly items-center md:p-20 sm:p-10 p-5 rounded-lg overflow-y-scroll">
        <h3 className="font-excelencya sm:text-5xl text-3xl sm:mt-0 mt-8 text-center text-primary">
          ¿En qué podemos ayudarte?
        </h3>
        <FormGenerator
          ref={formRef}
          inputs={contactInputs}
          onSubmit={handleFormSubmit}
          numberOfColumns={window.innerWidth < 768 ? 1 : 2} // Can be changed to try with more columns
          scrollable
          listenEnterKey // Remove this prop to disable the enter key listener
          buttonText="Finalizar"
          buttonClassName="bg-primary text-white hover:bg-transparent hover:text-primary hover:border-2 hover:border-primary font-bold py-2 px-4 rounded-lg transition-all duration-200"
        >
          {/* If necesary, you can put your own inputs as childs of the generator, and specify where they must be rendered by using 'childrenPosition' prop */}
        </FormGenerator>
      </div>
    </Modal>
  )
})

import { contactValidators } from './contactValidators'
import moment from 'moment'

export const contactInputs = [
  {
    tag: 'Nombre',
    name: 'name',
    type: 'text',
    defaultValue: '',
    isRequired: true,
    validators: [
      contactValidators.validName,
      contactValidators.notEmptyValidator,
    ],
  },
  {
    tag: 'Apellidos',
    name: 'surname',
    type: 'text',
    defaultValue: '',
    isRequired: true,
    validators: [
      contactValidators.validSurname,
      contactValidators.notEmptyValidator,
    ],
  },
  {
    tag: 'Email',
    name: 'email',
    type: 'email',
    defaultValue: '',
    isRequired: true,
    validators: [
      contactValidators.emailValidator,
      contactValidators.notEmptyValidator,
    ],
  },
  {
    tag: 'Teléfono',
    name: 'phone',
    type: 'text',
    defaultValue: '',
    isRequired: true,
    validators: [
      contactValidators.validPhoneNumber,
      contactValidators.notEmptyValidator,
    ],
  },
  {
    tag: 'Fecha del evento',
    name: 'date',
    type: 'date',
    defaultValue: moment().format('DD-MM-YYYY'),
    isRequired: true,
    validators: [contactValidators.notEmptyValidator],
  },
  {
    tag: 'Número de invitados',
    name: 'numberOfGuests',
    type: 'number',
    defaultValue: '100',
    isRequired: true,
    validators: [
      contactValidators.notEmptyValidator,
      contactValidators.minNumber,
    ],
  },
  {
    tag: 'Tramo',
    name: 'time',
    type: 'select',
    values: ['Mañana', 'Tarde'],
    defaultValue: 'Mañana',
    isRequired: true,
    validators: [
      contactValidators.validTimeOption,
      contactValidators.notEmptyValidator,
    ],
  },
  {
    tag: 'Tipo de evento',
    name: 'ceremony',
    type: 'select',
    values: ['Boda Religiosa', 'Boda Civil', 'Evento Social' , 'Evento Corporativo'],
    defaultValue: 'Boda Religiosa',
    isRequired: true,
    validators: [
      contactValidators.validCeremonyOption,
      contactValidators.notEmptyValidator,
    ],
  },
  {
    tag: '¿Cómo imaginas tu evento?',
    name: 'description',
    type: 'textarea',
    defaultValue: '',
    isRequired: true,
    validators: [
      contactValidators.descriptionValidator,
    ],
  },
]

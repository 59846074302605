import { lazy, Suspense } from 'react'
import { Outlet, Navigate, useRoutes } from 'react-router-dom'
import Header from '../components/header/header'
import Footer from '../components/footer/Footer'
// import ScrollToTop from './components/ScrollToTop'

export const NotFoundView = lazy(() => import('../pages/NotFoundView'))
export const HomePage = lazy(() => import('../pages/HomePage'))
export const MeetUs = lazy(() => import('../pages/MeetUs'))
export const Services = lazy(() => import('../pages/Services'))

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          {/* <ScrollToTop /> */}
          <Header />
          <Outlet />
          <Footer />
        </Suspense>
      ),
      children: [
        { element: <HomePage />, index: true },
        // { path: 'about', element: <MeetUs /> },
        // { path: 'services', element: <Services /> },
      ],
    },
    {
      path: 'error',
      element: <NotFoundView />,
    },
    //{ path: 'contract', element: <ContractPage /> },
    {
      path: '*',
      element: <Navigate to="/error" replace />,
    },
  ])

  return routes
}

import PropTypes from "prop-types";

export default function ExcelencyaLogo({ className, style, silver }) {
  return (
    <img
      className={`w-full h-full ${className}`}
      src={silver ? "./assets/logo-silver.png" : "./assets/logo.png"}
      alt="Excelencya Logo"
      style={style}
    />
  )
}

ExcelencyaLogo.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  silver: PropTypes.bool,
};

ExcelencyaLogo.defaultProps = {
  className: "",
  style: {},
  silver: false,
}
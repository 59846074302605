import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import ExcelencyaLogo from '../logo/ExcelencyaLogo'
import ContactModal from '../modal/ContactModal'
//import { FaBars, FaXmark } from 'react-icons/fa6'


export default function Header() {
  const modal = useRef(null)

  function handleModal() {
    modal.current.open()
  }

  const desktopLinks = [
    // { id: 1, name: 'Inicio', path: '/' },
    // { id: 2, name: 'Conócenos', path: '/about' },
    { id: 3, name: 'InicioLogo', path: '/' },
    // { id: 4, name: 'Servicios', path: '/services' },
    // { id: 5, name: 'Contacto', path: '/contact' },
  ]
  // const mobileLinks = [
  //   // { id: 6, name: 'Inicio', path: '/' },
  //   // { id: 7, name: 'Conócenos', path: '/about' },
  //   // { id: 8, name: 'Servicios', path: '/services' },
  //   // { id: 9, name: 'Contacto', path: '/contact' },
  // ]

  const [hoveredLink, setHoveredLink] = useState(null)
  //const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const changeScrolled = () => {
    if (window.scrollY >= 80) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  // const closeMobileMenu = () => {
  //   setMobileMenuOpen(false)
  // }

  useEffect(() => {
    window.addEventListener('scroll', changeScrolled)
    return () => window.removeEventListener('scroll', changeScrolled)
  })

  return (
    <>
      <header className="flex sm:justify-evenly md:justify-between items-center py-1 w-full fixed top-0 left-0 z-20">
        <div
          className={`w-full h-full absolute top-0 left-0 -z-10 ${
            scrolled ? 'bg-white' : 'blur-md bg-white/75'
          }`}
        />
        {desktopLinks.map((link) => {
          if (link.name === 'InicioLogo') {
            return (
              <Link
                to={link.path}
                key={link.id}
                className={`text-2xl font-bold ${
                  hoveredLink === link.id || hoveredLink === null
                    ? 'text-primary'
                    : 'text-gray-600'
                } px-5 hover:cursor:pointer sm:w-full w-1/2`}
                onMouseEnter={() => setHoveredLink(link.id)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                <ExcelencyaLogo
                  className={` h-full
                      max-h-[200px] max-w-[400px]
                   transition-all duration-500`}
                />
              </Link>
            )
          } else if (link.name === 'Contacto') {
            return (
              <button
                key={link.id}
                className={`text-2xl font-bold ${
                  hoveredLink === link.id || hoveredLink === null
                    ? 'text-primary'
                    : 'text-gray-600'
                } py-5 px-5 hover:cursor:pointer`}
                onMouseEnter={() => setHoveredLink(link.id)}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={handleModal}
              >
                {link.name}
              </button>
            )
          } else {
            return (
              <Link
                to={link.path}
                key={link.id}
                className={`text-2xl font-bold ${
                  hoveredLink === link.id || hoveredLink === null
                    ? 'text-primary'
                    : 'text-gray-600'
                } py-5 px-5 transition-all duration-500 hover:cursor-pointer`}
                onMouseEnter={() => setHoveredLink(link.id)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                {link.name}
              </Link>
            )
          }
        })}
        <button
          className="absolute top-1/2 sm:right-10 right-3 -translate-y-1/2 border-primary hover:border-transparent border-2 bg-transparent hover:bg-primary text-primary hover:text-white transition-all duration-200 sm:py-2 py-1 sm:px-4 px-2 sm:h-1/3 h-2/3 sm:w-[180px] w-1/3 sm:text-lg text-sm rounded-lg uppercase font-garamond font-bold hover:cursor-pointer"
          onClick={handleModal}
        >
          Contáctanos
        </button>
      </header>
      <ContactModal ref={modal} />
      {/* <header className="flex sm:hidden justify-between items-center py-5 w-full fixed top-0 left-0 z-20">
        <div
          className={`w-full h-full absolute top-0 left-0 -z-10 ${
            scrolled ? 'bg-white' : 'blur-md bg-white/75'
          }`}
        />
        <div className="w-1/2">
          <ExcelencyaLogo className={'max-h-[200px] max-w-[400px] px-5 py-2'} />
        </div>
        <button className="mr-5">
          <FaBars
            className="text-4xl text-primary"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          />
        </button>
        <div
          className={`${
            mobileMenuOpen ? 'left-0' : '-left-full'
          } absolute top-0 transition-all duration-500 flex flex-col bg-white h-lvh w-lvw`}
        >
          <div className="flex px-5 py-12 justify-between">
            <div className="w-1/2">
              <ExcelencyaLogo className={'max-h-[200px] max-w-[400px]'} />
            </div>
            <button>
              <FaXmark
                className="text-4xl text-primary"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              />
            </button>
          </div>
          <div className="flex flex-col justify-evenly py-12">
            
            {mobileLinks.map((link) => (
              <Link
                to={link.path}
                key={link.id}
                className={`text-2xl font-bold text-primary py-5 px-5`}
                onClick={closeMobileMenu}
              >
                {link.name}
              </Link>
            ))}
            <Link to="/" className="text-2xl font-bold text-primary py-5 px-5">
              Inicio
            </Link>
            <Link to="/" className="text-2xl font-bold text-primary py-5 px-5">
              Conócenos
            </Link>
            <Link to="/" className="text-2xl font-bold text-primary py-5 px-5">
              Servicios
            </Link>
            <Link to="/" className="text-2xl font-bold text-primary py-5 px-5">
              Testimoniales
            </Link>
            <Link to="/" className="text-2xl font-bold text-primary py-5 px-5">
              Contacto
            </Link>
          </div>
        </div>
      </header> */}
    </>
  )
}

export const contactValidators = {

    validName: {
        validate: (value) => value.match(/^[a-zA-Z\s]*$/) && value.trim().length > 2,
        message: "El nombre debe contener solo letras y tener al menos 3 caracteres"
    },

    validSurname: {
        validate: (value) => value.trim().length > 2,
        message: "El apellido debe tener al menos 3 caracteres"
    },

    validPhoneNumber: {
        validate: (value) => value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) && value.trim().length > 8,
        message: "El número de teléfono debe contener solo números y tener al menos 9 caracteres (opcionalmente con prefijo internacional)"
    },

    minNumber: {
        validate: (value) => parseInt(value)>0,
        message: "El valor debe ser mayor de 0"
    },

    maxNumber: {
        validate: (value) => parseInt(value)<500000,
        message: "El valor debe ser menor de 500k"
    },

    notEmptyValidator: {
        validate: (value) => value.trim().length > 0,
        message: "El campo no puede estar vacío"
    },
    
    descriptionValidator: {
        validate: (value) => value.trim().length > 0,
        message: "Por favor, describe brevemente tu evento tal y como lo imaginas"
    },
    
    passwordLengthValidator: {
        validate: (value) => value.trim().length >= 6,
        message: "El campo debe tener al menos 6 caracteres"
    },
    
    emailValidator: {
        validate: (value) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i),
        message: "El campo debe ser un email válido"
    },
    
    validTimeOption: {
        validate: (value) => {
            let validOptions = ['Mañana', 'Tarde'];
            return validOptions.includes(value);
        },
        message: "Se debe elegir exclusivamente entre mañana y tarde",
    },

    validCeremonyOption: {
        validate: (value) => {
            let validOptions = ['Boda Religiosa', 'Boda Civil', "Evento Social", "Evento Corporativo"];
            return validOptions.includes(value);
        },
        message: "Se debe elegir entre una de las opciones permitidas",
    },
}